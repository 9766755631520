import React from 'react'
import { PageProps, Link } from 'gatsby'

import * as styles from '../styles/pages/404.module.scss'

import { Layout } from '../components/layout'
import i18n from '../i18n'

const NotFound: React.FC<PageProps> = ({ location }) => {
  return (
    <Layout
      isLargeFooter={false}
      isHome={false}
      whiteHeader={false}
      location={location}
    >
      <div className={styles.notFound}>
        <div className={styles.initialWrapper}>
          <div className="container d-flex justify-content-center align-items-center">
            <div className="col-12 col-sm-10 col-lg-8">
              <div className={styles.title}>
                <h1>
                  <span>{i18n[`common.pageNotFound`]}</span>
                </h1>
                <p>{i18n[`common.notFound`]}</p>
                <Link to={`/`}>{i18n[`common.backHome`]}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFound
