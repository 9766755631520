// extracted by mini-css-extract-plugin
export var blueGradient = "_404-module--blue-gradient--tPxkb";
export var blueGradientCitric = "_404-module--blue-gradient-citric--BTyoT";
export var darkBlueGradient = "_404-module--dark-blue-gradient--yb-p3";
export var darkGradient = "_404-module--dark-gradient--XvB61";
export var initialWrapper = "_404-module--initialWrapper--b3ui1";
export var lightGradient = "_404-module--light-gradient--HEVMi";
export var notFound = "_404-module--notFound--9vWg1";
export var purpleBlueGradient = "_404-module--purple-blue-gradient--JqXpg";
export var softBlueGradient = "_404-module--soft-blue-gradient--YAclM";
export var softGreyGradient = "_404-module--soft-grey-gradient--ERkNz";
export var title = "_404-module--title--N5haT";